import { FC, Fragment } from "react";
import InnerLayout from "../InnerLayout/InnerLayout";
import {
  ComponentMap,
  ComponentTypeNames,
  RichTextMap,
} from "./type-name-mappings";
import Gutter from "ui-kit/Gutter";
import { ThemeEnum, getThemeColors, hasTheme } from "./theme";
import ResponsiveGutter from "ui-kit/ResponsiveGutter";
import { HeroTypeEnum } from "./ContentfulHero";
import TrustPilotStars from "components/TrustPilotStars";
import { isOrg } from "utils/site";
import { FlexRow } from "ui-kit/Layout/Flex";
import Colors from "ui-kit/colors";
import Banner from "components/Banner/Banner";
import BannerFra from "components/BannerFraAbTest/Banner";
import { useRouter } from "next/router";

type ContentfulRichTextRendererProps = {
  component: any;
};

type ContentfulComponentsRendererProps = {
  components: any[];
  gutter?: JSX.Element;
};

type ContentfulLayoutRendererProps = {
  banner?: any;
  components: any[];
};

//swe is currently resctricted due to Kosumentverket
const TrustPilotBanner = () =>
  !isOrg("swe") ? (
    <FlexRow
      justifyContent="center"
      alignItems="center"
      style={{
        background: Colors[getThemeColors(ThemeEnum.Grey)!.backgroundColor],
        height: 50,
      }}
    >
      <TrustPilotStars fullWidth />
    </FlexRow>
  ) : null;

export const ComponentGutter = () => <Gutter size={10} />;

export const ContentfulRichTextRenderer: FC<
  ContentfulRichTextRendererProps
> = ({ component }) => {
  const Component = RichTextMap[component.__typename];

  if (!Component) {
    console.warn(`Unknown rich text ${JSON.stringify(component)}`);
    return null;
  }

  return <Component {...component} isRichText />;
};

export const ContentfulComponentsRenderer: FC<
  ContentfulComponentsRendererProps
> = ({ components, gutter }) => {
  return (
    <>
      {components
        .map((component, i) => {
          const Component = ComponentMap[component.__typename];

          if (!Component) {
            console.warn(`Unknown component ${JSON.stringify(component)}`);
            return null;
          }

          return (
            <Fragment key={i}>
              {gutter}
              <Component {...component} />
              {gutter}
            </Fragment>
          );
        })
        .flatMap((c) => (c ? [c] : []))
        .reduce<JSX.Element[]>((acc, c, index) => {
          const isFirst = acc.length === 0;
          const isThemeAfterTheme =
            hasTheme(components[index - 1]) && hasTheme(components[index]);

          return isFirst
            ? [c]
            : [
                ...acc,
                ...(isThemeAfterTheme
                  ? [
                      <ResponsiveGutter
                        key={`${index}_gutter`}
                        desktop={8}
                        mobile={8}
                      />,
                      <ResponsiveGutter
                        key={`${index}_gutter_2`}
                        desktop={8}
                      />,
                    ]
                  : [<ResponsiveGutter key={`${index}_gutter`} desktop={8} />]),
                c,
              ];
        }, [])}
    </>
  );
};

export const ContentfulLayoutRenderer: FC<ContentfulLayoutRendererProps> = ({
  banner,
  components,
}) => {
  const { asPath } = useRouter();
  const [firstComponent] = components;

  if (!firstComponent) {
    return null;
  }

  //TODO: Remove once new hero is used
  const firstComponentIsOldHero =
    firstComponent.__typename === ComponentTypeNames.Hero &&
    [
      HeroTypeEnum.StartpageHero,
      HeroTypeEnum.PuppyCampaignHero,
      HeroTypeEnum.KittenCampaignHero,
      HeroTypeEnum.PetsDeliHero,
      HeroTypeEnum.GermanOOHCampaignHero,
    ].includes(firstComponent.type);

  const firstComponentIsNewHero =
    firstComponent.__typename === ComponentTypeNames.Hero &&
    [
      HeroTypeEnum.StartpageHeroAll,
      HeroTypeEnum.StartpageHeroCat,
      HeroTypeEnum.StartpageHeroDog,
    ].includes(firstComponent.type);

  const firstComponentIsHero =
    firstComponentIsOldHero || firstComponentIsNewHero;

  return (
    <>
      {banner &&
        banner.text &&
        banner.linkText &&
        banner.linkHref &&
        (isOrg("fra") &&
        banner.linkText === "BONJOURLASSIE" &&
        asPath === "/" ? (
          <BannerFra
            text={banner.text}
            linkText={banner.linkText}
            linkHref={banner.linkHref}
          />
        ) : (
          <Banner
            text={banner.text}
            linkText={banner.linkText}
            linkHref={banner.linkHref}
          />
        ))}
      {firstComponentIsOldHero && (
        <>
          <ContentfulComponentsRenderer components={[firstComponent]} />
          <ResponsiveGutter desktop={8} />
        </>
      )}
      {firstComponentIsNewHero && (
        <>
          <InnerLayout backgroundColor={"white"} hideHeader>
            <ContentfulComponentsRenderer
              components={[firstComponent]}
              gutter={<ComponentGutter />}
            />
          </InnerLayout>
          <TrustPilotBanner />
          <ResponsiveGutter desktop={8} />
        </>
      )}
      <InnerLayout backgroundColor={"white"} hideHeader>
        <ContentfulComponentsRenderer
          components={firstComponentIsHero ? components.slice(1) : components}
          gutter={<ComponentGutter />}
        />
      </InnerLayout>
    </>
  );
};
